import { React, useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { ExclamationShieldOutline, LeftOutline } from 'antd-mobile-icons'
import '../Pay/pay.css'
import _ from 'lodash'
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { selectPay } from '../../request/api'


export default function ErcPay() { 
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('')
  const getData = async () => {
    const res = await selectPay({})
    setVal(res.data.btc)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='UserPay'>
      <div className='header'>
        <div className='backIcon' onClick={() => { navigate('/User') }}>
          <LeftOutline />
        </div>
        <p>{t('g2')}</p>
      </div>
      <div className='UserPay_box'>
        <div className='userPay_top'>
          <img src="/assets/index/BTC.png" alt="" />
          <p>BTC</p>
        </div>
        <div className='qrcode'>
          <div>
            <ExclamationShieldOutline style={{ color: '#000', margin: '3px 3px 0' }} />
            <p>{t('cz10')}</p>
          </div>
          <div className='code'>
            <QRCode
              id={'qrCode'}
              value={val}  //value参数为生成二维码的链接
              size={180} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
            />
          </div>
          <div className='payCode'>
            <p>{val}</p>
          </div>
          <div className='copy' onClick={() => {
            navigator.clipboard.writeText(val).then(() => {
              Toast.show({
                icon: 'success',
                content: t('t2'),
              })
            });
          }}>
            <p>{t('cz2')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
